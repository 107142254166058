<template>
  <div>
    <v-snackbar v-model="snackbar" :top="true" :color="color" :timeout="6000">
      <span v-if="snackbar_msg != null">{{ snackbar_msg }}</span>
      <v-btn dark text @click="snackbar = false">Close</v-btn>
    </v-snackbar>
    <v-row>
            <div style="position: fixed; z-index: 1;  background-color: white; width: 100%; top:55px;">
            <v-breadcrumbs :items="items" large style=" font-weight: 600; letter-spacing: 1px; ">

            </v-breadcrumbs>
            </div>
        </v-row><br><br><br />
        <div v-if="init_loading">
          <v-skeleton-loader v-bind="attrs" type="three-line,  article"></v-skeleton-loader>
        </div>
        <div v-else>
    <v-card class="card">
      <v-card-title class="heading justify-center">DAILY CHECK-IN/CHECK-OUT REPORT</v-card-title>
      <v-card-text>
        <br />
        <v-row>
          <v-col cols="12" sm="2">
            <label class="pl-5">From Date</label>

            <br />
            <!-- {{formdata.resedential_premit_issue_date}} -->
            <input type="date" placeholder="From Date" class="example" v-model="selectedfromdate" />
          </v-col>
          <v-col cols="12" sm="2">
            <label class="pl-5"> TO Date</label>

            <br />
            <!-- {{formdata.resedential_premit_issue_date}} -->
            <input type="date" placeholder="To Date" class="example" v-model="selectedtodate" />
          </v-col>
          <v-col cols="12" sm="2">
            <label class="pl-5">Series</label>
            <br />
            <v-autocomplete v-model="selectedseries" outlined :items="series_data" item-text="name"
              item-value="id"></v-autocomplete>
          </v-col>
          <v-col cols="12" sm="2">
            <label class="pl-5">Status</label>
            <br />
            <v-autocomplete v-model="selectedstatus" outlined :items="status_data" item-text="name"
              item-value="id"></v-autocomplete>
          </v-col>
          <v-col cols="12" sm="2">
            <label class="pl-5">Usertype</label>
            <br />
            <v-autocomplete v-model="selectedutype" outlined :items="utype_data" item-text="name" item-value="id"
              @change="changeyear"></v-autocomplete>

          </v-col>
          <v-col v-if="display_year == true" cols="12" sm="2">
            <label class="pl-5">Academic Year</label>
            <br />
            <v-autocomplete v-model="selected_ay" outlined :items="ay_list" item-text="ay"
              item-value="id"></v-autocomplete>

          </v-col>
          <v-col v-if="display_year == true" cols="12" sm="2">
            <label class="pl-5">Year</label>
            <br />
            <v-autocomplete v-model="selected_year" outlined :items="year_list" item-text="year"
              item-value="id"  @change="getmemberfromyear()"></v-autocomplete>

          </v-col>
          <v-col v-if="display_year == true" cols="12" sm="2">
            <label class="pl-5">Division</label>
            <br />
            <v-autocomplete v-model="selected_division" outlined :items="division_list" ></v-autocomplete>

          </v-col>
          <v-col cols="12" sm="2">
            <label class="pl-5">Select member</label>
            <br />
            <v-combobox class="text" v-model="selecteduser" :items="empmemberlist" item-text="displayname"
              item-value="username" outlined dense clearable @click="isempselected = false"
              @change="set1(selecteduser, 'emp')"></v-combobox>

          </v-col>
          <br />
          <v-col cols="12" sm="4"></v-col>
          <v-col cols="12" sm="4">
            <center>
              <!-- {{formdata.resedential_premit_issue_date}} -->
              <v-btn class="primary" @click="gethistory">Search<v-icon>mdi-search</v-icon></v-btn>
            </center>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    </div>

    <div v-if="loader">
          <v-skeleton-loader v-bind="attrs" type="three-line,  article"></v-skeleton-loader>
        </div>
        <div v-else>
    <template v-if="Array.isArray(bookhistory) && bookhistory.length">
      <v-data-table :headers="headers" :items="bookhistory" :search="search" class="elevation-1 nowrap">
        <template v-slot:top>
          <v-toolbar flat color="white">
            <h3>ISSUE BOOK DETAILS</h3>
            <br />

            <v-spacer></v-spacer>
            <v-text-field v-model="search" append-icon="mdi-magnify" label="Search in table" single-line
              hide-details></v-text-field>
            <v-spacer></v-spacer>

            <!-- <download-excel
              class="ml-5"
              :data="bookhistory"
              :fields="headers_excel"
              worksheet="Issuebookreport"
              name="IssueBookReport.xls"
            >
              <v-btn color="success" dark class="mb-2">
                Export<v-icon dark>mdi-file-excel</v-icon>
              </v-btn>
            </download-excel> -->
            <excelheader :headers="headers" :tabledata="bookhistory" report_name="DAILY CHECK-IN/CHECK-OUT REPORT">
            </excelheader>
          </v-toolbar>
          <br>
          <span class='ml-4'><strong>Total Count : {{ in_count + out_count }} | ISSUE : {{ in_count }} | RETURN : {{
            out_count }}
            </strong></span>
        </template>
        <template v-slot:item.issue_date="{ item }">
          <!-- <v-icon v-if="item.edit==true" color="success" class="mr-2">mdi-check-circle-outline</v-icon> -->
          <span v-if="item != null && item.issue_date != '' && item.issue_date != null && item.issue_date != 'null'">{{
            item.issue_date.split("T")[0].split("-")[2] +
            "-" +
            item.issue_date.split("T")[0].split("-")[1] +
            "-" +
            item.issue_date.split("T")[0].split("-")[0]
          }}</span>
          <span v-else></span>
        </template>
        <template v-slot:item.return_date="{ item }">
          <!-- <v-icon v-if="item.edit==true" color="success" class="mr-2">mdi-check-circle-outline</v-icon> -->
          <span v-if="item != null && item.return_date != '' && item.return_date != null && item.return_date != 'null'">{{
            item.return_date.split("T")[0].split("-")[2] +
            "-" +
            item.return_date.split("T")[0].split("-")[1] +
            "-" +
            item.return_date.split("T")[0].split("-")[0]
          }}</span>
          <span v-else></span>
        </template>
        <template v-slot:item.iscurrent="{ item }">
          <span> {{ item.iscurrent }}</span>

        </template>
      </v-data-table>
    </template>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import moment from "moment";
import { table2excel } from "../jquery.table2excel";
import xlsx_header from "@/components/common_table_header.vue";
import "@/assets/css/color.css"; // css needs to be imported for each dashboard
export default {
  components: {
    "excelheader": xlsx_header
  },
  data: () => ({
    year_list: [],
    ay_list: [],
    items: [
      {
        text: 'Dashboard',
        disabled: false,
        href: '/home',
      },
      {
        text: localStorage.getItem("jhhjbyj"),
        disabled: false,
        href: '/sublink',
      },
      {
        text: 'Report',
        disabled: false,
        href: '/reports',
      },
      {
        text: 'DAILY CHECK-IN/CHECK-OUT REPORT',
        disabled: true,
        href: '',
      },
    ],
    itemlist: [],
    bookhistory: [],
    isempselected: false,
    init_loading:false,
    selecteduser: "",
    loader: false,
    selectedseries: "",
    selectedstatus: "",
    selectedutype: "",
    series_data: [],
    status_data: [],
    utype_data: [],
    out_count: '',
    in_count: "",
    seletedbook: "",
    selectedfromdate: null,
    selectedtodate: null,
    snackbar: false,
    snackbar_msg: "",
    color: "",
    search: "",
    division_list:[],
    selected_division:"",
    headers: [
      { text: "Sr no", value: "srno" },
      { text: "Accession No", value: "acc_no" },
      // { text: "Isbn No", value: "isbn_no" },
      { text: "Book Title", value: "book_title" },
      { text: "Author", value: "author" },
      { text: "Issue Date", value: "issue_date" },
      { text: "Return Date", value: "return_date" },
      { text: "Academic Year", value: "ay" },
      { text: "PRN/Empid", value: "grno_empid" },
      { text: "Year", value: "year" },
      { text: "Division", value: "division" },
      { text: "Borrowing Person", value: "name" },
      { text: "Usertype", value: "usertype" },
      { text: "Status", value: "iscurrent" },
    ],
    headers_excel: {
      "Sr no": "srno",
      "Accession No": "acc_no",
      "Author": "author",
      // "Isbn No": "isbn_no",
      "Book Title": "book_title",
      "Issue Date": "issue_date",
      "Issue Date": {
        field: "issue_date",
        callback: (item) => {
          return item == "" || item == null || item == 'null'
            ? ""
            : item.split("T")[0].split("-")[2] +
            "-" +
            item.split("T")[0].split("-")[1] +
            "-" +
            item.split("T")[0].split("-")[0];
        },
      },
      "Return Date": {
        field: "return_date",
        callback: (item) => {
          return item == "" || item == null || item == 'null'
            ? ""
            : item.split("T")[0].split("-")[2] +
            "-" +
            item.split("T")[0].split("-")[1] +
            "-" +
            item.split("T")[0].split("-")[0];
        },
      },
      "PRN/EMPID": "grno_empid",
      "Year": "year",
      "Division": "division",
      "Borrowing Person": "name",
      Usertype: "usertype",
      "Status": "iscurrent",
      "Total IN": "in_count",
      "Total OUT": "out_count",
    },
    selected_year: "",
    display_year: false,
    selected_ay: "",
    member_loader:false,
    empmemberlist:[]
  }),

  mounted() {
    this.onLoad();
  },

  methods: {
    getmemberfromyear()
    {
      this.member_loader = true;
      var params = {
        year: this.selected_year,
        ay: this.selected_ay,
      };
      axios
        .post("/Librarian/getmemberfromyear", params)
        .then((res) => {
          if (res.data.msg == "200") {
            this.empmemberlist = res.data.userlist;
            this.member_loader = false;
          }
          else{
            this.member_loader = false;
          }

        });
    },
    changeyear() {
      var item = this.utype_data.find((item) => item.id == this.selectedutype);

      if (item.name == 'Member')
        this.display_year = true;
      else
        this.display_year = false;
    },

    showSnackbar(clr, msg) {
      this.snackbar = true;
      this.color = clr;
      this.snackbar_msg = msg;
    },
    onLoad() {
      this.init_loading = true;
      axios
        .post("/Librarian/getissuebookdata")
        .then((res) => {
          if (res.data.msg == "200") {
            this.init_loading = false;
            this.series_data = res.data.series_data;
            this.empmemberlist = res.data.userlist;
            this.series_data.push({ name: "ALL", id: "ALL" });
            this.selectedseries = "ALL";

            this.status_data.push({ name: "ALL", id: "ALL" });
            this.status_data.push({ name: "Check In", id: "checkIn" });
            this.status_data.push({ name: "Check Out", id: "checkOut" });
            this.selectedstatus = "ALL";
            this.utype_data = res.data.utype_data;
            this.utype_data.push({ name: "ALL", id: "ALL" });
            this.selectedutype = "ALL";
            this.year_list = res.data.year_list;
            this.ay_list = res.data.ay_list;
            this.year_list.push({ year: "ALL", id: "ALL" });
            this.selected_year = 'ALL';
            this.selected_ay = res.data.select_ay;
            this.division_list = res.data.division_list;
            this.division_list.push("ALL");
            this.selected_division = 'ALL';

          }
        })
        .catch((error) => {
          this.showSnackbar("#b71c1c", "Something went wrong..."); // show snackbar
          window.console.log(error);
          this.init_loading = false;
        })
        .finally(() => {
          // var overlay = false;
        });
    },

    set1(value, person) {
      if (person == "emp") {
        this.prn = value.displayname.split(":")[0].trim();
        this.isempselected = true;
      }
    },
    exportexcel() {
      $("#exceltable").table2excel({
        name: "Worksheet Name",
        filename: "DALIY_CHECK-IN/CHECK-OUT_REPORT", //do not include extension
        fileext: ".xls", // file extension
      });
    },
    gethistory() {
      if (!this.selectedfromdate) {
        this.showSnackbar("red", "Please Select From Date");
        return;
      }
      if (!this.selectedtodate) {
        this.showSnackbar("red", "Please Select To Date");
        return;
      }
      this.loader = true;
      var params = {
        fromdate: this.selectedfromdate,
        todate: this.selectedtodate,
        series: this.selectedseries,
        status: this.selectedstatus,
        utype: this.selectedutype,
        person: this.prn,
        year: this.selected_year,
        ay: this.selected_ay,
        division:this.selected_division
      };
      axios
        .post("/Librarian/getissuereport", params)
        .then((res) => {
          if (res.data.code == "200") {
            this.bookhistory = res.data.bookhistory;
            this.in_count = res.data.in_count;
            this.out_count = res.data.out_count;
            this.loader = false;
            //console.log(this.bookhistory);
          }

        });
    },
  },
};
</script>
<style scoped>
.v-text-field {
  padding-top: 0px;
  margin-top: 0px;
}

.v-icon-size {
  font-size: 20px;
}

.edit-avatar {
  transition: 0.3s;
  border: 1px solid #b0bec5;
}

.edit-avatar:hover {
  background-color: #b0bec5;
}

.edit-v-icon {
  padding-left: 6px;
  font-size: 15px;
}

.edit-v-icon:hover {
  color: white;
}

.fields {
  padding: 0px !important;
}

.isdonated {
  margin-top: 15px;
  padding-left: 15px !important;
}

.check {
  color: green;
  font-size: 20px;
  padding-right: 10px;
}

.cross {
  color: red;
  font-size: 20px;
  padding-right: 5px;
}

.example {
  margin: 0px;
  background: #ffff;
  border: 1px solid rgb(171, 169, 169);
  border-radius: 5px;
  height: 55px;
  width: 100%;
  transition-duration: 0.2s;
}

.example:focus {
  border-radius: 50px;
  border-color: #2f74b0;
  border-width: 2px;

  outline: 0;
}

.required:after {
  content: " *";
  color: red;
}

.date {
  border: 2px solid red;
}
</style>